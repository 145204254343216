// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$String = require("rescript/lib/js/string.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");

var _m = 1000 * 60;

var _h = _m * 60;

var _d = _h * 24;

function _format(t, d, unit) {
  var s = String(Math.round(t / d));
  return "" + s + " " + unit;
}

function ms(v) {
  if (v >= _d) {
    return _format(v, _d, "d");
  } else if (v >= _h || v >= _m) {
    return _format(v, _h, "h");
  } else if (v >= 1000) {
    return _format(v, 1000, "s");
  } else {
    return _format(v, 1, "ms");
  }
}

var Format = {
  _s: 1000,
  _m: _m,
  _h: _h,
  _d: _d,
  _format: _format,
  ms: ms
};

function hash(input) {
  var value = {
    contents: 0
  };
  $$String.iter((function (c) {
          value.contents = ((value.contents << 5) - value.contents | 0) + c | 0;
          
        }), input);
  return Math.abs(value.contents);
}

function selectColor(colors, string) {
  var choices = colors.length;
  var index = Caml_int32.mod_(hash(string), choices);
  return Caml_array.get(colors, index);
}

function timer(param) {
  var prev = {
    contents: Date.now()
  };
  return function (param) {
    var now = Date.now();
    var delta = now - prev.contents;
    prev.contents = now;
    return delta;
  };
}

exports.Format = Format;
exports.hash = hash;
exports.selectColor = selectColor;
exports.timer = timer;
/* No side effect */
