// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Decco = require("decco/src/Decco.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var Log$SmartcutsMacros = require("../../bs-macros-extension/src/Log.bs.js");

function promiseWithIndex(fn, xs) {
  return Belt_Array.reduceWithIndex(xs, Promise.resolve(undefined), (function (promise, x, index) {
                return promise.then(function (result) {
                            if (result !== undefined) {
                              return Promise.resolve(result);
                            } else {
                              return Curry._2(fn, x, index);
                            }
                          });
              }));
}

function promise(fn, xs) {
  return promiseWithIndex((function (x, _index) {
                return Curry._1(fn, x);
              }), xs);
}

var Shortcut = {
  promiseWithIndex: promiseWithIndex,
  promise: promise
};

var Invalid_argument = /* @__PURE__ */Caml_exceptions.create("Util-SmartcutsMacros.Invalid_argument");

function noop_0(param) {
  
}

function noop_1(param) {
  
}

function spyTransform(msg, transform, x) {
  Curry._2(Log$SmartcutsMacros.utilSpy.log2, msg, Curry._1(transform, x));
  return x;
}

function spyPromise1(msg, promise) {
  return promise.then(function (x) {
              Curry._2(Log$SmartcutsMacros.utilSpy.log2, msg, x);
              return Promise.resolve(x);
            });
}

function shortcut(fn, xs) {
  return $$Array.fold_left((function (result, x) {
                if (result !== undefined) {
                  return result;
                } else {
                  return Curry._1(fn, x);
                }
              }), undefined, xs);
}

function shortcut2(xs, fn) {
  return shortcut(fn, xs);
}

function shortcutWithIndex(fn, xs) {
  return Belt_Array.reduceWithIndex(xs, undefined, (function (result, x, index) {
                if (result !== undefined) {
                  return result;
                } else {
                  return Curry._2(fn, x, index);
                }
              }));
}

function shortcutWithIndex2(xs, fn) {
  return shortcutWithIndex(fn, xs);
}

function shortcutPromise(fn, xs) {
  return $$Array.fold_left((function (promise, x) {
                return promise.then(function (result) {
                            if (result !== undefined) {
                              return Promise.resolve(result);
                            } else {
                              return Curry._1(fn, x);
                            }
                          });
              }), Promise.resolve(undefined), xs);
}

function shortcutList(fn, xs) {
  return List.fold_left((function (result, x) {
                if (result !== undefined) {
                  return result;
                } else {
                  return Curry._1(fn, x);
                }
              }), undefined, xs);
}

function shortcutListWithIndex(fn, xs) {
  return Belt_List.reduceWithIndex(xs, undefined, (function (result, x, index) {
                if (result !== undefined) {
                  return result;
                } else {
                  return Curry._2(fn, x, index);
                }
              }));
}

function logAndRaiseJsError(message) {
  try {
    return Js_exn.raiseError(message);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Js_exn.$$Error) {
      console.error(exn._1);
      throw exn;
    }
    throw exn;
  }
}

function compose(f, g, a) {
  return Curry._1(f, Curry._1(g, a));
}

function not_(x) {
  return !x;
}

function stringify(stringifyOk, stringifyError, result) {
  if (result.TAG === /* Ok */0) {
    return "Ok(" + (Curry._1(stringifyOk, result._0) + ")");
  } else {
    return "Error(" + (Curry._1(stringifyError, result._0) + ")");
  }
}

var Result = {
  stringify: stringify
};

function t_encode(id) {
  return id;
}

function t_decode(encoded) {
  var num = Js_json.decodeNumber(encoded);
  if (num !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: num | 0
          };
  } else {
    return Decco.error(undefined, "Expected json number", encoded);
  }
}

var GlobalTimeoutId = {
  t_encode: t_encode,
  t_decode: t_decode
};

function t_encode$1(id) {
  return id;
}

function t_decode$1(encoded) {
  var num = Js_json.decodeNumber(encoded);
  if (num !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: num | 0
          };
  } else {
    return Decco.error(undefined, "Expected json number", encoded);
  }
}

var GlobalIntervalId = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

var TimedOut = /* @__PURE__ */Caml_exceptions.create("Util-SmartcutsMacros.WaitFor.TimedOut");

var PredicateTimedOut = /* @__PURE__ */Caml_exceptions.create("Util-SmartcutsMacros.WaitFor.PredicateTimedOut");

var PredicateError = /* @__PURE__ */Caml_exceptions.create("Util-SmartcutsMacros.WaitFor.PredicateError");

var WaitFor = {
  TimedOut: TimedOut,
  PredicateTimedOut: PredicateTimedOut,
  PredicateError: PredicateError,
  checkIntervalMs: 500,
  defaultTimeout: 15000.0
};

function wait(millis) {
  return new Promise((function (resolve, param) {
                var timeoutId = {
                  contents: 0
                };
                timeoutId.contents = setTimeout((function (param) {
                        return resolve(timeoutId.contents);
                      }), millis);
                
              }));
}

function waitFloat(millis) {
  return new Promise((function (resolve, param) {
                var timeoutId = {
                  contents: 0
                };
                timeoutId.contents = setTimeout((function (param) {
                        return resolve(timeoutId.contents);
                      }), millis);
                
              }));
}

function timedOutError(defaultExn, maybeRaiseExn) {
  if (maybeRaiseExn !== undefined) {
    return Curry._1(maybeRaiseExn, undefined);
  } else {
    return defaultExn;
  }
}

function waitForPromise(timeoutOpt, intervalOpt, predicate, maybeRaiseExn, param) {
  var timeout = timeoutOpt !== undefined ? timeoutOpt : 15000.0;
  var interval = intervalOpt !== undefined ? intervalOpt : 500;
  var predicateDidTimeout = {
    contents: false
  };
  var onPredicateTimedOutCallbacks = {
    contents: []
  };
  var addOnPredicateTimedOutCallback = function (callback) {
    if (predicateDidTimeout.contents) {
      return Curry._1(callback, undefined);
    } else {
      onPredicateTimedOutCallbacks.contents = $$Array.append(onPredicateTimedOutCallbacks.contents, [callback]);
      return ;
    }
  };
  var onPredicateTimedOut = function (param) {
    return Belt_Array.forEach(onPredicateTimedOutCallbacks.contents, (function (callback) {
                  return Curry._1(callback, undefined);
                }));
  };
  return new Promise((function (resolve, reject) {
                var epoch = Date.now();
                var loop = function (param) {
                  var millisElapsed = Date.now() - epoch;
                  Curry._1(Log$SmartcutsMacros.utilWaitPromise.log1, "WAITED FOR " + (millisElapsed.toString() + (" milliseconds relative to timeout " + (timeout.toString() + (" milliseconds (remaining timeout = " + ((timeout - millisElapsed).toString() + " milliseconds)"))))));
                  if (millisElapsed > timeout) {
                    return reject(timedOutError({
                                    RE_EXN_ID: TimedOut,
                                    _1: timeout
                                  }, maybeRaiseExn));
                  }
                  var predicateDidResolve = {
                    contents: false
                  };
                  var predicateTimeoutId = setTimeout((function (param) {
                          if (predicateDidResolve.contents) {
                            return ;
                          } else {
                            predicateDidTimeout.contents = true;
                            onPredicateTimedOut(undefined);
                            return reject(timedOutError({
                                            RE_EXN_ID: PredicateTimedOut,
                                            _1: timeout
                                          }, maybeRaiseExn));
                          }
                        }), timeout - millisElapsed);
                  Curry._1(predicate, addOnPredicateTimedOutCallback).then(function (result) {
                          predicateDidResolve.contents = true;
                          if (predicateDidTimeout.contents) {
                            return Promise.resolve(undefined);
                          } else {
                            clearTimeout(predicateTimeoutId);
                            if (result !== undefined) {
                              resolve(Caml_option.valFromOption(result));
                              return Promise.resolve(undefined);
                            } else {
                              return wait(interval).then(function (param) {
                                          loop(undefined);
                                          return Promise.resolve(undefined);
                                        });
                            }
                          }
                        }).catch(function (error) {
                        reject({
                              RE_EXN_ID: PredicateError,
                              _1: error
                            });
                        return Promise.resolve(undefined);
                      });
                  
                };
                return loop(undefined);
              }));
}

function waitFor(timeout, interval, predicate, raiseExn, param) {
  var promisePredicate = function (param) {
    return Promise.resolve(Curry._1(predicate, undefined));
  };
  return waitForPromise(timeout, interval, promisePredicate, raiseExn, undefined);
}

var WaitFor2 = {};

function waitForPromise2(timeoutOpt, intervalOpt, predicate) {
  var timeout = timeoutOpt !== undefined ? timeoutOpt : 15000.0;
  var interval = intervalOpt !== undefined ? intervalOpt : 500;
  var predicateDidTimeout = {
    contents: false
  };
  var onPredicateTimedOutCallbacks = {
    contents: []
  };
  var addOnPredicateTimedOutCallback = function (callback) {
    if (predicateDidTimeout.contents) {
      return Curry._1(callback, undefined);
    } else {
      onPredicateTimedOutCallbacks.contents = $$Array.append(onPredicateTimedOutCallbacks.contents, [callback]);
      return ;
    }
  };
  var onPredicateTimedOut = function (param) {
    return Belt_Array.forEach(onPredicateTimedOutCallbacks.contents, (function (callback) {
                  return Curry._1(callback, undefined);
                }));
  };
  return new Promise((function (resolve, param) {
                var epoch = Date.now();
                var loop = function (maybeExtraInfo) {
                  var millisElapsed = Date.now() - epoch;
                  Curry._1(Log$SmartcutsMacros.utilWaitPromise.log1, "WAITED FOR " + (millisElapsed.toString() + (" milliseconds relative to timeout " + (timeout.toString() + (" milliseconds (remaining timeout = " + ((timeout - millisElapsed).toString() + " milliseconds)"))))));
                  if (millisElapsed > timeout) {
                    return resolve({
                                TAG: /* Ok */0,
                                _0: {
                                  TAG: /* TimedOut */1,
                                  _0: timeout,
                                  _1: maybeExtraInfo
                                }
                              });
                  }
                  var predicateDidResolve = {
                    contents: false
                  };
                  var predicateTimeoutId = setTimeout((function (param) {
                          if (predicateDidResolve.contents) {
                            return ;
                          } else {
                            predicateDidTimeout.contents = true;
                            onPredicateTimedOut(undefined);
                            return resolve({
                                        TAG: /* Ok */0,
                                        _0: {
                                          TAG: /* PredicateTimedOut */2,
                                          _0: timeout,
                                          _1: maybeExtraInfo
                                        }
                                      });
                          }
                        }), timeout - millisElapsed);
                  var exit = 0;
                  var predicatePromiseReturned;
                  try {
                    predicatePromiseReturned = Curry._1(predicate, addOnPredicateTimedOutCallback);
                    exit = 1;
                  }
                  catch (raw_e){
                    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                    Promise.resolve(resolve({
                              TAG: /* Error */1,
                              _0: {
                                TAG: /* UncaughtPredicateError */0,
                                _0: e
                              }
                            }));
                  }
                  if (exit === 1) {
                    predicatePromiseReturned.then(function (predicateOutcome) {
                            predicateDidResolve.contents = true;
                            if (predicateDidTimeout.contents) {
                              return Promise.resolve(undefined);
                            }
                            clearTimeout(predicateTimeoutId);
                            if (typeof predicateOutcome === "number") {
                              return wait(interval).then(function (param) {
                                          loop(undefined);
                                          return Promise.resolve(undefined);
                                        });
                            }
                            switch (predicateOutcome.TAG | 0) {
                              case /* PredicateSatisfied */0 :
                                  resolve({
                                        TAG: /* Ok */0,
                                        _0: {
                                          TAG: /* PredicateDidResolveTo */0,
                                          _0: predicateOutcome._0
                                        }
                                      });
                                  return Promise.resolve(undefined);
                              case /* PredicateNotSatisfiedWithExtraInfo */1 :
                                  var extraInfo = predicateOutcome._0;
                                  return wait(interval).then(function (param) {
                                              loop(Caml_option.some(extraInfo));
                                              return Promise.resolve(undefined);
                                            });
                              case /* ErrorDuringPredicate */2 :
                                  resolve({
                                        TAG: /* Error */1,
                                        _0: {
                                          TAG: /* PredicateError */1,
                                          _0: predicateOutcome._0
                                        }
                                      });
                                  return Promise.resolve(undefined);
                              
                            }
                          }).catch(function (predicateError) {
                          resolve({
                                TAG: /* Error */1,
                                _0: {
                                  TAG: /* UncaughtPredicateError */0,
                                  _0: predicateError
                                }
                              });
                          return Promise.resolve(undefined);
                        });
                  }
                  
                };
                return loop(undefined);
              }));
}

function nextTick(param) {
  return new Promise((function (resolve, param) {
                setTimeout((function (param) {
                        return resolve(undefined);
                      }), 0);
                
              }));
}

function make(v1, v2, v3) {
  return [
          {
            TAG: /* Member1 */0,
            _0: v1
          },
          {
            TAG: /* Member2 */1,
            _0: v2
          },
          {
            TAG: /* Member3 */2,
            _0: v3
          }
        ];
}

function reduce(fn, accum, combo) {
  return List.fold_left(fn, accum, {
              hd: combo[0],
              tl: {
                hd: combo[1],
                tl: {
                  hd: combo[2],
                  tl: /* [] */0
                }
              }
            });
}

var Combo3 = {
  make: make,
  reduce: reduce
};

var Combo = {
  Combo3: Combo3
};

function pow(base, exponent) {
  return Math.pow(base, exponent) | 0;
}

var QueryString = {};

function t_encode$2(encoder_a, encoder_error, v) {
  if (typeof v !== "number") {
    if (v.TAG === /* Loaded */0) {
      return [
              "Loaded",
              Curry._1(encoder_a, v._0)
            ];
    } else {
      return [
              "LoadDidErr",
              Curry._1(encoder_error, v._0)
            ];
    }
  }
  switch (v) {
    case /* HasNotRequested */0 :
        return ["HasNotRequested"];
    case /* Loading */1 :
        return ["Loading"];
    case /* CouldNotFind */2 :
        return ["CouldNotFind"];
    
  }
}

function t_decode$2(decoder_a, decoder_error, v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = jsonArr$1.map(Js_json.classify);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "CouldNotFind" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* CouldNotFind */2
                  };
          }
      case "HasNotRequested" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* HasNotRequested */0
                  };
          }
      case "LoadDidErr" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = Curry._1(decoder_error, Belt_Array.getExn(jsonArr$1, 1));
          if (v0.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* LoadDidErr */1,
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "Loaded" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = Curry._1(decoder_a, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$1.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Loaded */0,
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      case "Loading" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Loading */1
                  };
          }
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function getLoadedValue(v) {
  if (typeof v === "number" || v.TAG !== /* Loaded */0) {
    return ;
  } else {
    return Caml_option.some(v._0);
  }
}

function minify(minifyValue, v) {
  if (typeof v === "number" || v.TAG !== /* Loaded */0) {
    return v;
  } else {
    return {
            TAG: /* Loaded */0,
            _0: Curry._1(minifyValue, v._0)
          };
  }
}

function map(f, v) {
  if (typeof v === "number" || v.TAG !== /* Loaded */0) {
    return v;
  } else {
    return {
            TAG: /* Loaded */0,
            _0: Curry._1(f, v._0)
          };
  }
}

function stringify$1(stringifyValue, stringifyError, v) {
  if (typeof v !== "number") {
    if (v.TAG === /* Loaded */0) {
      return "Loaded(" + (Curry._1(stringifyValue, v._0) + ")");
    } else {
      return "LoadDidErr(" + (Curry._1(stringifyError, v._0) + ")");
    }
  }
  switch (v) {
    case /* HasNotRequested */0 :
        return "HasNotRequested";
    case /* Loading */1 :
        return "Loading";
    case /* CouldNotFind */2 :
        return "CouldNotFind";
    
  }
}

var Loadable = {
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  getLoadedValue: getLoadedValue,
  minify: minify,
  map: map,
  stringify: stringify$1
};

var $$Performance = {};

function t_encode$3(encoder_a, v) {
  if (v) {
    return [
            "NonDefault",
            Curry._1(encoder_a, v._0)
          ];
  } else {
    return ["Default"];
  }
}

function t_decode$3(decoder_a, v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = jsonArr$1.map(Js_json.classify);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "Default" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Default */0
                  };
          }
      case "NonDefault" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = Curry._1(decoder_a, Belt_Array.getExn(jsonArr$1, 1));
          if (v0.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: /* NonDefault */{
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function toOpt(v) {
  if (v) {
    return Caml_option.some(v._0);
  }
  
}

var HasDefault = {
  t_encode: t_encode$3,
  t_decode: t_decode$3,
  toOpt: toOpt
};

var hasSingleKey = (function (v, key) {
      let keys = Object.keys(v.raw);
      return (keys.length === 1 && keys[0] === key);
    });

var hasKeys = (function (v, keys) {
      return keys.every(function (key) { return !! v.raw[key]; });
    });

function isFloatWithin(lower, upper, n) {
  if (n >= lower) {
    return n <= upper;
  } else {
    return false;
  }
}

var isWebextensionPolyfillLoaded = (function () {return typeof browser != "undefined";});

function waitForWebextensionPolyfillLoaded(param) {
  return waitForPromise2(5000, 500, (function (param) {
                return Promise.resolve(Curry._1(isWebextensionPolyfillLoaded, undefined) ? ({
                                TAG: /* PredicateSatisfied */0,
                                _0: undefined
                              }) : /* PredicateNotSatisfied */0);
              }));
}

exports.Shortcut = Shortcut;
exports.Invalid_argument = Invalid_argument;
exports.noop_0 = noop_0;
exports.noop_1 = noop_1;
exports.spyTransform = spyTransform;
exports.spyPromise1 = spyPromise1;
exports.shortcut = shortcut;
exports.shortcut2 = shortcut2;
exports.shortcutWithIndex = shortcutWithIndex;
exports.shortcutWithIndex2 = shortcutWithIndex2;
exports.shortcutPromise = shortcutPromise;
exports.shortcutList = shortcutList;
exports.shortcutListWithIndex = shortcutListWithIndex;
exports.logAndRaiseJsError = logAndRaiseJsError;
exports.compose = compose;
exports.not_ = not_;
exports.Result = Result;
exports.GlobalTimeoutId = GlobalTimeoutId;
exports.GlobalIntervalId = GlobalIntervalId;
exports.WaitFor = WaitFor;
exports.wait = wait;
exports.waitFloat = waitFloat;
exports.waitForPromise = waitForPromise;
exports.waitFor = waitFor;
exports.WaitFor2 = WaitFor2;
exports.waitForPromise2 = waitForPromise2;
exports.nextTick = nextTick;
exports.Combo = Combo;
exports.pow = pow;
exports.QueryString = QueryString;
exports.Loadable = Loadable;
exports.$$Performance = $$Performance;
exports.HasDefault = HasDefault;
exports.hasSingleKey = hasSingleKey;
exports.hasKeys = hasKeys;
exports.isFloatWithin = isFloatWithin;
exports.isWebextensionPolyfillLoaded = isWebextensionPolyfillLoaded;
exports.waitForWebextensionPolyfillLoaded = waitForWebextensionPolyfillLoaded;
/* Log-SmartcutsMacros Not a pure module */
