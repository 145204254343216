// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ArrayUtils$SmartcutsMacros = require("./ArrayUtils.bs.js");

function s(prim) {
  return prim;
}

function n(prim) {
  return prim;
}

function inject(_cls, _fn) {
  return (_cls.getInitialProps = _fn);
}

function joinWith(xs, v) {
  var _xs = xs;
  var _ys = [];
  while(true) {
    var ys = _ys;
    var xs$1 = _xs;
    var match = ArrayUtils$SmartcutsMacros.splitAtLast(xs$1);
    var y = match[1];
    if (y === undefined) {
      return ys;
    }
    var y$1 = Caml_option.valFromOption(y);
    var match$1 = ys.length;
    var ys$p = match$1 !== 0 ? $$Array.append([
            y$1,
            v
          ], ys) : [y$1];
    _ys = ys$p;
    _xs = match[0];
    continue ;
  };
}

function joinWith_(v, xs) {
  return joinWith(xs, v);
}

exports.s = s;
exports.n = n;
exports.inject = inject;
exports.joinWith = joinWith;
exports.joinWith_ = joinWith_;
/* ArrayUtils-SmartcutsMacros Not a pure module */
