// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");

function log(args) {
  return 'object' === typeof console
    && console.log
    && Function.prototype.apply.call(console.log, console, args);
}
;

function warn(args) {
  return 'object' === typeof console
    && console.warn
    && Function.prototype.apply.call(console.warn, console, args);
}
;

function error(args) {
  return 'object' === typeof console
    && console.error
    && Function.prototype.apply.call(console.error, console, args);
}
;

function log$1(args) {
  log($$Array.of_list(args));
  
}

function warn$1(args) {
  warn($$Array.of_list(args));
  
}

function error$1(args) {
  error($$Array.of_list(args));
  
}

var colors = [
  "#0000CC",
  "#0000FF",
  "#0033CC",
  "#0033FF",
  "#0066CC",
  "#0066FF",
  "#0099CC",
  "#0099FF",
  "#00CC00",
  "#00CC33",
  "#00CC66",
  "#00CC99",
  "#00CCCC",
  "#00CCFF",
  "#3300CC",
  "#3300FF",
  "#3333CC",
  "#3333FF",
  "#3366CC",
  "#3366FF",
  "#3399CC",
  "#3399FF",
  "#33CC00",
  "#33CC33",
  "#33CC66",
  "#33CC99",
  "#33CCCC",
  "#33CCFF",
  "#6600CC",
  "#6600FF",
  "#6633CC",
  "#6633FF",
  "#66CC00",
  "#66CC33",
  "#9900CC",
  "#9900FF",
  "#9933CC",
  "#9933FF",
  "#99CC00",
  "#99CC33",
  "#CC0000",
  "#CC0033",
  "#CC0066",
  "#CC0099",
  "#CC00CC",
  "#CC00FF",
  "#CC3300",
  "#CC3333",
  "#CC3366",
  "#CC3399",
  "#CC33CC",
  "#CC33FF",
  "#CC6600",
  "#CC6633",
  "#CC9900",
  "#CC9933",
  "#CCCC00",
  "#CCCC33",
  "#FF0000",
  "#FF0033",
  "#FF0066",
  "#FF0099",
  "#FF00CC",
  "#FF00FF",
  "#FF3300",
  "#FF3333",
  "#FF3366",
  "#FF3399",
  "#FF33CC",
  "#FF33FF",
  "#FF6600",
  "#FF6633",
  "#FF9900",
  "#FF9933",
  "#FFCC00",
  "#FFCC33"
];

function format(namespace, diff, payload) {
  return "%c" + namespace + " %c" + payload + "%c +" + diff;
}

function group(args) {
  return 'object' === typeof console
    && console.group
    && Function.prototype.apply.call(console.group, console, args);
}
;

function group$1(args) {
  group($$Array.of_list(args));
  
}

function save(param) {
  
}

function load(param) {
  return process.env.DEBUG;
}

exports.log = log$1;
exports.warn = warn$1;
exports.error = error$1;
exports.colors = colors;
exports.format = format;
exports.group = group$1;
exports.save = save;
exports.load = load;
/*  Not a pure module */
