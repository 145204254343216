// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var OptionUtils$SmartcutsMacros = require("./OptionUtils.bs.js");

function isEmpty(l) {
  return List.length(l) === 0;
}

function last(_l) {
  while(true) {
    var l = _l;
    if (!l) {
      return ;
    }
    var rest = l.tl;
    if (!rest) {
      return Caml_option.some(l.hd);
    }
    _l = rest;
    continue ;
  };
}

function elemIndex(value, l) {
  var index = Belt_List.toArray(l).findIndex(function (x) {
        return Caml_obj.caml_equal(x, value);
      });
  if (index !== -1) {
    return index;
  }
  
}

function findIndex(f, l) {
  var _i = 0;
  var _l = l;
  while(true) {
    var l$1 = _l;
    var i = _i;
    if (!l$1) {
      return ;
    }
    if (Curry._1(f, l$1.hd)) {
      return i;
    }
    _l = l$1.tl;
    _i = i + 1 | 0;
    continue ;
  };
}

function findWithIndex(f, l) {
  var _i = 0;
  var _l = l;
  while(true) {
    var l$1 = _l;
    var i = _i;
    if (!l$1) {
      return ;
    }
    var x = l$1.hd;
    if (Curry._1(f, x)) {
      return [
              x,
              i
            ];
    }
    _l = l$1.tl;
    _i = i + 1 | 0;
    continue ;
  };
}

function getLast(xs) {
  var length = List.length(xs);
  if (length !== 0) {
    return Belt_List.get(xs, length - 1 | 0);
  }
  
}

function member(value, l) {
  return Belt_List.has(l, value, Caml_obj.caml_equal);
}

function sortBy(f, l) {
  return Belt_List.sort(l, (function (a, b) {
                var a$p = Curry._1(f, a);
                var b$p = Curry._1(f, b);
                if (Caml_obj.caml_equal(a$p, b$p)) {
                  return 0;
                } else if (Caml_obj.caml_lessthan(a$p, b$p)) {
                  return -1;
                } else {
                  return 1;
                }
              }));
}

function minimum(l) {
  if (!l) {
    return ;
  }
  var rest = l.tl;
  var x = l.hd;
  if (rest) {
    return Caml_option.some(Belt_List.reduce(rest, x, Caml_obj.caml_min));
  } else {
    return Caml_option.some(x);
  }
}

function drop(count, l) {
  return Belt_Option.getWithDefault(Belt_List.drop(l, count), /* [] */0);
}

function take(count, l) {
  return Belt_Option.getWithDefault(Belt_List.take(l, count), /* [] */0);
}

function splitAt(index, l) {
  return [
          take(index, l),
          drop(index, l)
        ];
}

function splitAtLast(xs) {
  var lastIndex = List.length(xs) - 1 | 0;
  var lst = Belt_List.take(xs, lastIndex);
  if (lst !== undefined) {
    return [
            lst,
            Belt_List.get(xs, lastIndex)
          ];
  } else {
    return [
            /* [] */0,
            Belt_List.get(xs, lastIndex)
          ];
  }
}

function updateAt(index, f, l) {
  if (index < 0) {
    return l;
  }
  var match = splitAt(index, l);
  var back = match[1];
  if (back) {
    return Belt_List.concat(match[0], {
                hd: Curry._1(f, back.hd),
                tl: back.tl
              });
  } else {
    return l;
  }
}

function updateFirstMatch(predicate, update, xs) {
  return $$Array.to_list(Belt_List.reduce(xs, [
                    [],
                    false
                  ], (function (param, x) {
                      var didUpdateMatch = param[1];
                      var updatedArray = param[0];
                      if (!(!didUpdateMatch && Curry._1(predicate, x))) {
                        return [
                                $$Array.append(updatedArray, [x]),
                                didUpdateMatch
                              ];
                      }
                      var nextUpdatedArray = $$Array.append(updatedArray, [Curry._1(update, x)]);
                      return [
                              nextUpdatedArray,
                              true
                            ];
                    }))[0]);
}

function tail(l) {
  if (l) {
    return l.tl;
  }
  
}

function removeAt(index, l) {
  if (index < 0) {
    return l;
  }
  var match = splitAt(index, l);
  var t = tail(match[1]);
  if (t !== undefined) {
    return Belt_List.concat(match[0], t);
  } else {
    return l;
  }
}

function remove(value, xs) {
  var index = elemIndex(value, xs);
  if (index !== undefined) {
    return removeAt(index, xs);
  } else {
    return xs;
  }
}

function upsert(value, xs) {
  var _index = elemIndex(value, xs);
  if (_index !== undefined) {
    return xs;
  } else {
    return {
            hd: value,
            tl: xs
          };
  }
}

function mapcat(f, xs) {
  return List.concat(List.map(f, xs));
}

function keepIndexes(indexes, xs) {
  return Belt_List.reduce(xs, [
                /* [] */0,
                0
              ], (function (param, x) {
                  var index = param[1];
                  var newList = param[0];
                  return [
                          List.exists((function (i) {
                                  return i === index;
                                }), indexes) ? ({
                                hd: x,
                                tl: newList
                              }) : newList,
                          index + 1 | 0
                        ];
                }))[0];
}

function filterMapi(f, xs) {
  return List.rev(Belt_List.reduce(xs, [
                    /* [] */0,
                    0
                  ], (function (param, x) {
                      var i = param[1];
                      var ys = param[0];
                      var y = Curry._2(f, i, x);
                      if (y !== undefined) {
                        return [
                                {
                                  hd: Caml_option.valFromOption(y),
                                  tl: ys
                                },
                                i + 1 | 0
                              ];
                      } else {
                        return [
                                ys,
                                i + 1 | 0
                              ];
                      }
                    }))[0]);
}

function filterMap(f, xs) {
  return filterMapi((function (param, x) {
                return Curry._1(f, x);
              }), xs);
}

function foldLefti(init, f, xs) {
  return Belt_List.reduce(xs, [
                init,
                0
              ], (function (param, currX) {
                  var index = param[1];
                  var nextVal = Curry._3(f, index, currX, param[0]);
                  return [
                          nextVal,
                          index + 1 | 0
                        ];
                }))[0];
}

function takeWhile(f, l) {
  var _acc = /* [] */0;
  var _l$p = l;
  while(true) {
    var l$p = _l$p;
    var acc = _acc;
    if (!l$p) {
      return Belt_List.reverse(acc);
    }
    var x = l$p.hd;
    if (!Curry._1(f, x)) {
      return Belt_List.reverse(acc);
    }
    _l$p = l$p.tl;
    _acc = {
      hd: x,
      tl: acc
    };
    continue ;
  };
}

function interleave(sep, xs) {
  var lastIndex = List.length(xs) - 1 | 0;
  return foldLefti(/* [] */0, (function (i, x, out) {
                return List.append(out, i === lastIndex ? ({
                                hd: x,
                                tl: /* [] */0
                              }) : ({
                                hd: x,
                                tl: {
                                  hd: sep,
                                  tl: /* [] */0
                                }
                              }));
              }), xs);
}

function collapsePairs(f, currList) {
  if (currList) {
    var _processedOpt;
    var _curr = currList.hd;
    var _remaining = currList.tl;
    while(true) {
      var processedOpt = _processedOpt;
      var remaining = _remaining;
      var curr = _curr;
      var processed = processedOpt !== undefined ? processedOpt : /* [] */0;
      if (!remaining) {
        return List.rev({
                    hd: curr,
                    tl: processed
                  });
      }
      var nextList = remaining.tl;
      var next = remaining.hd;
      var replaceWith = Curry._3(f, curr, next, nextList);
      if (typeof replaceWith === "number") {
        if (replaceWith === /* KeepBoth */0) {
          _remaining = nextList;
          _curr = next;
          _processedOpt = {
            hd: curr,
            tl: processed
          };
          continue ;
        }
        if (!nextList) {
          return List.rev(processed);
        }
        if (processed) {
          _remaining = nextList;
          _curr = processed.hd;
          _processedOpt = processed.tl;
          continue ;
        }
        _remaining = nextList.tl;
        _curr = nextList.hd;
        _processedOpt = processed;
        continue ;
      } else {
        switch (replaceWith.TAG | 0) {
          case /* ReplaceWith */0 :
              _remaining = nextList;
              _curr = replaceWith._0;
              _processedOpt = processed;
              continue ;
          case /* KeepFirstAndRemoveNumAfterFirst */1 :
              var nextListWithSubsequentRemoved = OptionUtils$SmartcutsMacros.withDefault(/* [] */0, Belt_List.drop(nextList, replaceWith._0 - 1 | 0));
              var newProcessed = {
                hd: curr,
                tl: processed
              };
              if (!nextListWithSubsequentRemoved) {
                return List.rev(newProcessed);
              }
              _remaining = nextListWithSubsequentRemoved.tl;
              _curr = nextListWithSubsequentRemoved.hd;
              _processedOpt = newProcessed;
              continue ;
          case /* ReplaceFirstAndKeepSecond */2 :
              _remaining = nextList;
              _curr = next;
              _processedOpt = {
                hd: replaceWith._0,
                tl: processed
              };
              continue ;
          
        }
      }
    };
  } else {
    return /* [] */0;
  }
}

function collapseTriplets(f, currList) {
  if (!currList) {
    return currList;
  }
  var match = currList.tl;
  if (!match) {
    return currList;
  }
  var match$1 = match.tl;
  if (match$1) {
    var _processedOpt;
    var _first = currList.hd;
    var _second = match.hd;
    var _third = match$1.hd;
    var _remaining = match$1.tl;
    while(true) {
      var processedOpt = _processedOpt;
      var remaining = _remaining;
      var third = _third;
      var second = _second;
      var first = _first;
      var processed = processedOpt !== undefined ? processedOpt : /* [] */0;
      var match$2 = Curry._4(f, first, second, third, remaining);
      var nextFirst;
      var nextSecond;
      var nextThird;
      var nextRemaining;
      if (typeof match$2 === "number") {
        if (match$2 !== 0) {
          if (!remaining) {
            return List.rev(processed);
          }
          var match$3 = remaining.tl;
          if (!match$3) {
            return List.append(List.rev(processed), remaining);
          }
          var match$4 = match$3.tl;
          if (!match$4) {
            return List.append(List.rev(processed), remaining);
          }
          nextFirst = remaining.hd;
          nextSecond = match$3.hd;
          nextThird = match$4.hd;
          nextRemaining = match$4.tl;
        } else {
          if (!remaining) {
            return List.rev({
                        hd: third,
                        tl: {
                          hd: second,
                          tl: {
                            hd: first,
                            tl: processed
                          }
                        }
                      });
          }
          _remaining = remaining.tl;
          _third = remaining.hd;
          _second = third;
          _first = second;
          _processedOpt = {
            hd: first,
            tl: processed
          };
          continue ;
        }
      } else {
        var match$5 = match$2._0;
        if (match$5) {
          var match$6 = match$5.tl;
          var nextFirst$1 = match$5.hd;
          if (match$6) {
            var match$7 = match$6.tl;
            var nextSecond$1 = match$6.hd;
            if (match$7) {
              _remaining = List.append(match$7.tl, remaining);
              _third = match$7.hd;
              _second = nextSecond$1;
              _first = nextFirst$1;
              _processedOpt = processed;
              continue ;
            }
            if (!remaining) {
              return List.append(List.rev(processed), {
                          hd: nextFirst$1,
                          tl: {
                            hd: nextSecond$1,
                            tl: /* [] */0
                          }
                        });
            }
            _remaining = remaining.tl;
            _third = remaining.hd;
            _second = nextSecond$1;
            _first = nextFirst$1;
            _processedOpt = processed;
            continue ;
          }
          if (!remaining) {
            return List.append(List.rev(processed), {
                        hd: nextFirst$1,
                        tl: remaining
                      });
          }
          var match$8 = remaining.tl;
          if (!match$8) {
            return List.append(List.rev(processed), {
                        hd: nextFirst$1,
                        tl: remaining
                      });
          }
          _remaining = match$8.tl;
          _third = match$8.hd;
          _second = remaining.hd;
          _first = nextFirst$1;
          _processedOpt = processed;
          continue ;
        }
        if (!remaining) {
          return List.rev(processed);
        }
        var match$9 = remaining.tl;
        if (!match$9) {
          return List.append(List.rev(processed), remaining);
        }
        var match$10 = match$9.tl;
        if (!match$10) {
          return List.append(List.rev(processed), remaining);
        }
        nextFirst = remaining.hd;
        nextSecond = match$9.hd;
        nextThird = match$10.hd;
        nextRemaining = match$10.tl;
      }
      _remaining = nextRemaining;
      _third = nextThird;
      _second = nextSecond;
      _first = nextFirst;
      _processedOpt = processed;
      continue ;
    };
  } else {
    return currList;
  }
}

function shortcutWithIndexAndState(init, f, lst) {
  return Belt_List.reduceWithIndex(lst, [
                init,
                undefined
              ], (function (param, x, index) {
                  var maybeResult = param[1];
                  var state = param[0];
                  if (maybeResult !== undefined) {
                    return [
                            state,
                            maybeResult
                          ];
                  } else {
                    return Curry._3(f, state, x, index);
                  }
                }))[1];
}

function shortcutWithState(init, f, lst) {
  return shortcutWithIndexAndState(init, (function (state, x, _index) {
                return Curry._2(f, state, x);
              }), lst);
}

function shortcutWithIndex(f, lst) {
  return shortcutWithIndexAndState(undefined, (function (unit, x, index) {
                return [
                        unit,
                        Curry._2(f, x, index)
                      ];
              }), lst);
}

function shortcut(f, lst) {
  return shortcutWithIndexAndState(undefined, (function (unit, x, _index) {
                return [
                        unit,
                        Curry._1(f, x)
                      ];
              }), lst);
}

function removeValue(value, l) {
  var index = findIndex((function (x) {
          return Caml_obj.caml_equal(x, value);
        }), l);
  if (index !== undefined) {
    return removeAt(index, l);
  } else {
    return l;
  }
}

function removeValues(values, l) {
  return Belt_List.keep(l, (function (x) {
                return !List.exists((function (value) {
                              return Caml_obj.caml_equal(value, x);
                            }), values);
              }));
}

function firstMatch(f, lst) {
  return shortcut((function (x) {
                if (Curry._1(f, x)) {
                  return Caml_option.some(x);
                }
                
              }), lst);
}

function unique(xs) {
  return Belt_List.reduce(xs, /* [] */0, (function (uniques, x) {
                if (List.mem(x, uniques)) {
                  return uniques;
                } else {
                  return List.append(uniques, {
                              hd: x,
                              tl: /* [] */0
                            });
                }
              }));
}

function mapWithState(init, f, xs) {
  return Belt_List.reduce(xs, [
                /* [] */0,
                init
              ], (function (param, x) {
                  var match = Curry._2(f, param[1], x);
                  return [
                          Belt_List.concat(param[0], {
                                hd: match[1],
                                tl: /* [] */0
                              }),
                          match[0]
                        ];
                }))[0];
}

var prepend = Belt_List.add;

exports.isEmpty = isEmpty;
exports.last = last;
exports.elemIndex = elemIndex;
exports.findIndex = findIndex;
exports.findWithIndex = findWithIndex;
exports.getLast = getLast;
exports.member = member;
exports.sortBy = sortBy;
exports.minimum = minimum;
exports.drop = drop;
exports.take = take;
exports.splitAt = splitAt;
exports.prepend = prepend;
exports.splitAtLast = splitAtLast;
exports.updateAt = updateAt;
exports.updateFirstMatch = updateFirstMatch;
exports.tail = tail;
exports.removeAt = removeAt;
exports.remove = remove;
exports.upsert = upsert;
exports.mapcat = mapcat;
exports.keepIndexes = keepIndexes;
exports.filterMapi = filterMapi;
exports.filterMap = filterMap;
exports.foldLefti = foldLefti;
exports.takeWhile = takeWhile;
exports.interleave = interleave;
exports.collapsePairs = collapsePairs;
exports.collapseTriplets = collapseTriplets;
exports.shortcutWithIndexAndState = shortcutWithIndexAndState;
exports.shortcutWithState = shortcutWithState;
exports.shortcutWithIndex = shortcutWithIndex;
exports.shortcut = shortcut;
exports.removeValue = removeValue;
exports.removeValues = removeValues;
exports.firstMatch = firstMatch;
exports.unique = unique;
exports.mapWithState = mapWithState;
/* No side effect */
