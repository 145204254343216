// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function $$return(v) {
  return Caml_option.some(v);
}

function wrap(fn, opt) {
  if (opt !== undefined) {
    return Caml_option.some(Curry._1(fn, Caml_option.valFromOption(opt)));
  }
  
}

function $great$great$eq(opt, fn) {
  if (opt !== undefined) {
    return Curry._1(fn, Caml_option.valFromOption(opt));
  }
  
}

function $neg$question$great(opt, fn) {
  if (opt !== undefined) {
    return Caml_option.some(Curry._1(fn, Caml_option.valFromOption(opt)));
  }
  
}

exports.$$return = $$return;
exports.wrap = wrap;
exports.$great$great$eq = $great$great$eq;
exports.$neg$question$great = $neg$question$great;
/* No side effect */
