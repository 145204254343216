// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var Util$SmartcutsMacros = require("./Util.bs.js");
var ListUtils$SmartcutsMacros = require("./ListUtils.bs.js");
var OptionUtils$SmartcutsMacros = require("./OptionUtils.bs.js");

function isEmpty(xs) {
  return xs.length === 0;
}

function sort(f, xs) {
  var copyOfXs = $$Array.copy(xs);
  $$Array.sort(f, copyOfXs);
  return copyOfXs;
}

function sort2(xs, f) {
  var copyOfXs = $$Array.copy(xs);
  $$Array.sort(f, copyOfXs);
  return copyOfXs;
}

function sortByIntAttributeDescending(xs, getAttr) {
  return Belt_SortArray.stableSortBy(xs, (function (val_1, val_2) {
                return Curry._1(getAttr, val_2) - Curry._1(getAttr, val_1) | 0;
              }));
}

function get(index, xs) {
  return Belt_Array.get(xs, index);
}

function slice(from, to_, arr) {
  var defaultTo = to_ !== undefined ? to_ : arr.length;
  var sliceFrom = from >= 0 ? Caml.caml_int_min(from, arr.length) : Caml.caml_int_max(0, Caml.caml_int_min(arr.length, arr.length + from | 0));
  var sliceTo = defaultTo >= 0 ? Caml.caml_int_min(arr.length, defaultTo) : Caml.caml_int_max(0, Caml.caml_int_min(arr.length, arr.length + defaultTo | 0));
  if (sliceFrom >= sliceTo) {
    return [];
  } else {
    return Belt_Array.makeBy(sliceTo - sliceFrom | 0, (function (i) {
                  return Caml_array.get(arr, i + sliceFrom | 0);
                }));
  }
}

function insertBefore(index, x, xs) {
  return $$Array.concat({
              hd: Belt_Array.slice(xs, 0, index),
              tl: {
                hd: [x],
                tl: {
                  hd: Belt_Array.sliceToEnd(xs, index),
                  tl: /* [] */0
                }
              }
            });
}

function insertAfter(index, x, xs) {
  return $$Array.concat({
              hd: Belt_Array.slice(xs, 0, index + 1 | 0),
              tl: {
                hd: [x],
                tl: {
                  hd: Belt_Array.sliceToEnd(xs, index + 1 | 0),
                  tl: /* [] */0
                }
              }
            });
}

function updateAt(fillWith, index, f, xs) {
  var oldX = Belt_Array.get(xs, index);
  if (oldX !== undefined) {
    return $$Array.concat({
                hd: Belt_Array.slice(xs, 0, index),
                tl: {
                  hd: OptionUtils$SmartcutsMacros.withDefault([], OptionUtils$SmartcutsMacros.map(Curry._1(f, Caml_option.some(Caml_option.valFromOption(oldX))), (function (newX) {
                              return [newX];
                            }))),
                  tl: {
                    hd: Belt_Array.sliceToEnd(xs, index + 1 | 0),
                    tl: /* [] */0
                  }
                }
              });
  }
  var lastIndex = xs.length - 1 | 0;
  return $$Array.concat({
              hd: xs,
              tl: {
                hd: Caml_array.make((index - lastIndex | 0) - 1 | 0, fillWith),
                tl: {
                  hd: OptionUtils$SmartcutsMacros.withDefault([], OptionUtils$SmartcutsMacros.map(Curry._1(f, undefined), (function (newX) {
                              return [newX];
                            }))),
                  tl: /* [] */0
                }
              }
            });
}

function updateAtWithoutFill(index, f, xs) {
  var len = xs.length;
  var oldX = Belt_Array.get(xs, index);
  if (oldX !== undefined) {
    return $$Array.concat({
                hd: Belt_Array.slice(xs, 0, index),
                tl: {
                  hd: OptionUtils$SmartcutsMacros.withDefault([], OptionUtils$SmartcutsMacros.map(Curry._1(f, Caml_option.some(Caml_option.valFromOption(oldX))), (function (newX) {
                              return [newX];
                            }))),
                  tl: {
                    hd: Belt_Array.sliceToEnd(xs, index + 1 | 0),
                    tl: /* [] */0
                  }
                }
              });
  } else if (index === len) {
    return $$Array.concat({
                hd: xs,
                tl: {
                  hd: OptionUtils$SmartcutsMacros.withDefault([], OptionUtils$SmartcutsMacros.map(Curry._1(f, undefined), (function (newX) {
                              return [newX];
                            }))),
                  tl: /* [] */0
                }
              });
  } else {
    return Util$SmartcutsMacros.logAndRaiseJsError("Can't updateAtWithoutFill an index > xs.length");
  }
}

function setWithoutFill(index, x, xs) {
  var len = xs.length;
  var _oldX = Belt_Array.get(xs, index);
  if (_oldX !== undefined) {
    return $$Array.concat({
                hd: Belt_Array.slice(xs, 0, index),
                tl: {
                  hd: [x],
                  tl: {
                    hd: (index + 1 | 0) > (len - 1 | 0) ? [] : Belt_Array.sliceToEnd(xs, index + 1 | 0),
                    tl: /* [] */0
                  }
                }
              });
  } else if (index === len) {
    return $$Array.concat({
                hd: Belt_Array.slice(xs, 0, len),
                tl: {
                  hd: [x],
                  tl: /* [] */0
                }
              });
  } else {
    return Util$SmartcutsMacros.logAndRaiseJsError("Can't setWithoutFill an index > xs.length");
  }
}

function setWithoutFill2(xs, index, x) {
  return setWithoutFill(index, x, xs);
}

function setAtIndexAndFillGap(index, x, fill, xs) {
  var len = xs.length;
  var xs$p;
  if (index > len) {
    var fillGap = Belt_Array.make(index - len | 0, fill);
    xs$p = Belt_Array.concat(xs, fillGap);
  } else {
    xs$p = xs;
  }
  return setWithoutFill(index, x, xs$p);
}

function removeIndex(index, xs) {
  if (index === 0) {
    return Belt_Array.sliceToEnd(xs, 1);
  } else {
    return $$Array.concat({
                hd: Belt_Array.slice(xs, 0, index),
                tl: {
                  hd: index >= (xs.length - 1 | 0) ? [] : Belt_Array.sliceToEnd(xs, index + 1 | 0),
                  tl: /* [] */0
                }
              });
  }
}

function upsertBy(condition, element, xs) {
  var index = Belt_Array.getIndexBy(xs, condition);
  if (index === undefined) {
    return $$Array.append(xs, [element]);
  }
  var param = function (maybeEntry) {
    return OptionUtils$SmartcutsMacros.map(maybeEntry, (function (param) {
                  return element;
                }));
  };
  return updateAtWithoutFill(index, param, xs);
}

function prependElement(xs, x) {
  return $$Array.append([x], xs);
}

function enqueue(x, xs) {
  return $$Array.append(xs, [x]);
}

function dequeue(xs) {
  var dequeuedElement = Belt_Array.get(xs, 0);
  if (dequeuedElement !== undefined) {
    return [
            Caml_option.valFromOption(dequeuedElement),
            slice(1, undefined, xs)
          ];
  }
  
}

function substituteFirstOccurrence(predicate, replaceWith, arr) {
  var index = Belt_Array.getIndexBy(arr, predicate);
  if (index === undefined) {
    return arr;
  }
  var arg = Belt_Array.get(arr, index);
  var memberToUpdate = (function (param) {
        return OptionUtils$SmartcutsMacros.getExn(param, arg);
      })("expected memberToUpdate in substituteFirstOccurrence");
  return Belt_Array.concatMany([
              Belt_Array.slice(arr, 0, index),
              [Curry._1(replaceWith, memberToUpdate)],
              Belt_Array.slice(arr, index + 1 | 0, (arr.length - index | 0) - 1 | 0)
            ]);
}

function flatMapWithIndex(f, arr) {
  return Belt_Array.concatMany(Belt_Array.mapWithIndex(arr, f));
}

function flatMap(f, arr) {
  return Belt_Array.concatMany($$Array.map(f, arr));
}

function groupBy(f, xs) {
  return Belt_Array.reduce(xs, undefined, (function (groupsByKey, x) {
                var key = Curry._1(f, x);
                return Belt_MapString.update(groupsByKey, key, (function (maybeValue) {
                              if (maybeValue !== undefined) {
                                return $$Array.append(maybeValue, [x]);
                              } else {
                                return [x];
                              }
                            }));
              }));
}

function inGroupsOf(count, xs) {
  var lastIndex = xs.length - 1 | 0;
  return Belt_Array.reduce(xs, [
                [],
                0,
                /* [] */0
              ], (function (param, x) {
                  var index = param[1];
                  var arrayOfGroups = param[0];
                  var updatedGroupInReverse_1 = param[2];
                  var updatedGroupInReverse = {
                    hd: x,
                    tl: updatedGroupInReverse_1
                  };
                  if (index !== lastIndex && List.length(updatedGroupInReverse) < count) {
                    return [
                            arrayOfGroups,
                            index + 1 | 0,
                            updatedGroupInReverse
                          ];
                  }
                  var group = List.rev(updatedGroupInReverse);
                  var newArrayOfGroups = Belt_Array.concat(arrayOfGroups, [group]);
                  return [
                          newArrayOfGroups,
                          index + 1 | 0,
                          /* [] */0
                        ];
                }))[0];
}

function groupIntoPairs(xs) {
  return $$Array.map((function (group) {
                if (!group) {
                  return Util$SmartcutsMacros.logAndRaiseJsError("Array doesn't have an even number of members; need to have an even number of members to group them into pairs");
                }
                var match = group.tl;
                if (match && !match.tl) {
                  return [
                          group.hd,
                          match.hd
                        ];
                } else {
                  return Util$SmartcutsMacros.logAndRaiseJsError("Array doesn't have an even number of members; need to have an even number of members to group them into pairs");
                }
              }), inGroupsOf(2, xs));
}

function calcArrayIndexIfRemovedIndexes(removedIndexes, indexPriorToRemovals) {
  if (List.exists((function (i) {
            return i === indexPriorToRemovals;
          }), removedIndexes)) {
    return ;
  }
  var removedIndexesPriorToTargetIndex = ListUtils$SmartcutsMacros.takeWhile((function (removedIndex) {
          return removedIndex < indexPriorToRemovals;
        }), removedIndexes);
  return indexPriorToRemovals - List.length(removedIndexesPriorToTargetIndex) | 0;
}

function replaceAtIndex(xs, index, y) {
  return $$Array.mapi((function (index$p, x) {
                if (index === index$p) {
                  return y;
                } else {
                  return x;
                }
              }), xs);
}

function splitAtLast(xs) {
  var lastIndex = xs.length - 1 | 0;
  return [
          Belt_Array.slice(xs, 0, lastIndex),
          Belt_Array.get(xs, lastIndex)
        ];
}

function splitAtIndex(xs, index) {
  return [
          Belt_Array.slice(xs, 0, index),
          Belt_Array.sliceToEnd(xs, index)
        ];
}

function splitAtFirstExn(msg, xs) {
  var arg = Belt_Array.get(xs, 0);
  return [
          (function (param) {
                return OptionUtils$SmartcutsMacros.getExn(param, arg);
              })(msg),
          Belt_Array.sliceToEnd(xs, 1)
        ];
}

function splitAtFirst(xs) {
  return Belt_Option.map(Belt_Array.get(xs, 0), (function (x0) {
                return [
                        x0,
                        Belt_Array.sliceToEnd(xs, 1)
                      ];
              }));
}

function filterMapi(f, xs) {
  return Belt_Array.reduceWithIndex(xs, [], (function (out, a, i) {
                var b = Curry._2(f, i, a);
                if (b !== undefined) {
                  return $$Array.append(out, [Caml_option.valFromOption(b)]);
                } else {
                  return out;
                }
              }));
}

function filterMap(f, xs) {
  return filterMapi((function (param, a) {
                return Curry._1(f, a);
              }), xs);
}

function keepAsync(predicatePromise, arr) {
  return Promise.all($$Array.map((function (x) {
                      return Curry._1(predicatePromise, x).then(function (shouldKeep) {
                                  return Promise.resolve([
                                              shouldKeep,
                                              x
                                            ]);
                                });
                    }), arr)).then(function (pairsOfShouldKeepAndElement) {
              return Promise.resolve(Belt_Array.map(Belt_Array.keep(pairsOfShouldKeepAndElement, (function (param) {
                                    return param[0];
                                  })), (function (prim) {
                                return prim[1];
                              })));
            });
}

function keepNot(f, xs) {
  return Belt_Array.keep(xs, (function (x) {
                return !Curry._1(f, x);
              }));
}

function unique(xs) {
  return Belt_Array.reduce(xs, [], (function (uniques, x) {
                if ($$Array.mem(x, uniques)) {
                  return uniques;
                } else {
                  return $$Array.append(uniques, [x]);
                }
              }));
}

function shortcutWithIndexAndState(init, f, xs) {
  return Belt_Array.reduceWithIndex(xs, [
                init,
                undefined
              ], (function (param, x, index) {
                  var maybeResult = param[1];
                  var state = param[0];
                  if (maybeResult !== undefined) {
                    return [
                            state,
                            maybeResult
                          ];
                  } else {
                    return Curry._3(f, state, x, index);
                  }
                }))[1];
}

function shortcutWithState(init, f, xs) {
  return shortcutWithIndexAndState(init, (function (state, x, _index) {
                return Curry._2(f, state, x);
              }), xs);
}

function shortcutWithIndex(f, xs) {
  return shortcutWithIndexAndState(undefined, (function (unit, x, index) {
                return [
                        unit,
                        Curry._2(f, x, index)
                      ];
              }), xs);
}

function shortcut(f, xs) {
  return shortcutWithIndexAndState(undefined, (function (unit, x, _index) {
                return [
                        unit,
                        Curry._1(f, x)
                      ];
              }), xs);
}

function everyWithIndex(xs, fn) {
  return Belt_Array.every(Belt_Array.mapWithIndex(xs, (function (i, x) {
                    return [
                            i,
                            x
                          ];
                  })), (function (param) {
                return Curry._2(fn, param[0], param[1]);
              }));
}

function last(xs) {
  var n = xs.length;
  if (n !== 0) {
    return Belt_Array.get(xs, n - 1 | 0);
  }
  
}

function firstMatch(f, lst) {
  return shortcut((function (x) {
                if (Curry._1(f, x)) {
                  return Caml_option.some(x);
                }
                
              }), lst);
}

function firstMatchi(f, lst) {
  return shortcutWithIndex((function (x, index) {
                if (Curry._1(f, x)) {
                  return [
                          x,
                          index
                        ];
                }
                
              }), lst);
}

function shortcutWithIndexAndStateAsync(init, f, xs) {
  return Belt_Array.reduceWithIndex(xs, Promise.resolve([
                    init,
                    undefined
                  ]), (function (promise, x, index) {
                  return promise.then(function (param) {
                              if (param[1] !== undefined) {
                                return promise;
                              } else {
                                return Curry._3(f, param[0], x, index);
                              }
                            });
                })).then(function (param) {
              return Promise.resolve(param[1]);
            });
}

function shortcutAsync(f, xs) {
  return shortcutWithIndexAndStateAsync(undefined, (function (param, x, _index) {
                return Curry._1(f, x).then(function (y) {
                            return Promise.resolve([
                                        undefined,
                                        y
                                      ]);
                          });
              }), xs);
}

function windowMap2(f, arr) {
  var len = arr.length;
  if (len < 2) {
    return Util$SmartcutsMacros.logAndRaiseJsError("windowMap2 can only be applied to array with at least 2 elements");
  } else {
    return filterMapi((function (i, a) {
                  if (i === (len - 1 | 0)) {
                    return ;
                  } else {
                    return Caml_option.some(Curry._2(f, a, Caml_array.get(arr, i + 1 | 0)));
                  }
                }), arr);
  }
}

function window2ReduceWithIndex(xs, init, f) {
  return Belt_Array.reduceWithIndex(xs, init, (function (result, x, index) {
                var x$p = Belt_Array.get(xs, index + 1 | 0);
                if (x$p !== undefined) {
                  return Curry._4(f, result, x, Caml_option.valFromOption(x$p), index);
                } else {
                  return result;
                }
              }));
}

function smallest(compare, xs) {
  var match = splitAtFirstExn("Calling smallest() on empty array", xs);
  return Belt_Array.reduce(match[1], match[0], (function (smallest, x) {
                if (Curry._2(compare, smallest, x) > 0) {
                  return x;
                } else {
                  return smallest;
                }
              }));
}

function takeWhile(xs, f) {
  var match = firstMatchi((function (x) {
          return !Curry._1(f, x);
        }), xs);
  if (match !== undefined) {
    return Belt_Array.slice(xs, 0, match[1]);
  } else {
    return xs;
  }
}

function collapsePairs(f, currArray) {
  var match = splitAtFirst(currArray);
  if (match !== undefined) {
    var _processedOpt;
    var _curr = match[0];
    var _remaining = match[1];
    while(true) {
      var processedOpt = _processedOpt;
      var remaining = _remaining;
      var curr = _curr;
      var processed = processedOpt !== undefined ? processedOpt : [];
      var match$1 = splitAtFirst(remaining);
      if (match$1 === undefined) {
        return Belt_Array.concat(processed, [curr]);
      }
      var nextArray = match$1[1];
      var next = match$1[0];
      var replaceWith = Curry._3(f, curr, next, nextArray);
      if (typeof replaceWith === "number") {
        if (replaceWith === /* KeepBoth */0) {
          var newProcessed = Belt_Array.concat(processed, [curr]);
          _remaining = nextArray;
          _curr = next;
          _processedOpt = newProcessed;
          continue ;
        }
        var match$2 = splitAtFirst(nextArray);
        if (match$2 === undefined) {
          return processed;
        }
        var match$3 = splitAtFirst(processed);
        if (match$3 !== undefined) {
          _remaining = nextArray;
          _curr = match$3[0];
          _processedOpt = match$3[1];
          continue ;
        }
        _remaining = match$2[1];
        _curr = match$2[0];
        _processedOpt = processed;
        continue ;
      } else {
        switch (replaceWith.TAG | 0) {
          case /* ReplaceWith */0 :
              _remaining = nextArray;
              _curr = replaceWith._0;
              _processedOpt = processed;
              continue ;
          case /* KeepFirstAndRemoveNumAfterFirst */1 :
              var nextArrayWithSubsequentRemoved = Belt_Array.sliceToEnd(nextArray, replaceWith._0 - 1 | 0);
              var newProcessed$1 = Belt_Array.concat(processed, [curr]);
              var match$4 = splitAtFirst(nextArrayWithSubsequentRemoved);
              if (match$4 === undefined) {
                return newProcessed$1;
              }
              _remaining = match$4[1];
              _curr = match$4[0];
              _processedOpt = newProcessed$1;
              continue ;
          case /* ReplaceFirstAndKeepSecond */2 :
              var newProcessed$2 = Belt_Array.concat(processed, [replaceWith._0]);
              _remaining = nextArray;
              _curr = next;
              _processedOpt = newProcessed$2;
              continue ;
          
        }
      }
    };
  } else {
    return [];
  }
}

function packBy(f, xs) {
  return $$Array.of_list(Belt_List.reverse(Belt_Array.reduce(xs, /* [] */0, (function (groups, x) {
                        if (!groups) {
                          return {
                                  hd: [x],
                                  tl: groups
                                };
                        }
                        var currentGroup = groups.hd;
                        if (Curry._2(f, x, currentGroup)) {
                          return {
                                  hd: Belt_Array.concat(currentGroup, [x]),
                                  tl: groups.tl
                                };
                        } else {
                          return {
                                  hd: [x],
                                  tl: groups
                                };
                        }
                      }))));
}

function fillTo(xs, len, value) {
  if (xs.length >= len) {
    return xs;
  } else {
    return Belt_Array.concat(xs, Caml_array.make(len - xs.length | 0, value));
  }
}

function splitAtEveryOccurrences(sequence, value) {
  var len = sequence.indexOf(value, 1);
  if (len !== -1) {
    return Belt_Array.concat([Belt_Array.slice(sequence, 0, len)], splitAtEveryOccurrences(Belt_Array.sliceToEnd(sequence, len), value));
  } else {
    return [sequence];
  }
}

function findOneWithLowestScoreExn(xs, f, msg) {
  var match = splitAtFirstExn(msg, xs);
  var first = match[0];
  return Belt_Array.reduce(match[1], [
                first,
                Curry._1(f, first)
              ], (function (param, item) {
                  var lowestScore = param[1];
                  var score = Curry._1(f, item);
                  if (score < lowestScore) {
                    return [
                            item,
                            score
                          ];
                  } else {
                    return [
                            param[0],
                            lowestScore
                          ];
                  }
                }))[0];
}

var mapcatWithIndex = flatMapWithIndex;

var mapcat = flatMap;

exports.isEmpty = isEmpty;
exports.sort = sort;
exports.sort2 = sort2;
exports.sortByIntAttributeDescending = sortByIntAttributeDescending;
exports.get = get;
exports.slice = slice;
exports.insertBefore = insertBefore;
exports.insertAfter = insertAfter;
exports.updateAt = updateAt;
exports.updateAtWithoutFill = updateAtWithoutFill;
exports.setWithoutFill = setWithoutFill;
exports.setWithoutFill2 = setWithoutFill2;
exports.setAtIndexAndFillGap = setAtIndexAndFillGap;
exports.removeIndex = removeIndex;
exports.upsertBy = upsertBy;
exports.prependElement = prependElement;
exports.enqueue = enqueue;
exports.dequeue = dequeue;
exports.substituteFirstOccurrence = substituteFirstOccurrence;
exports.flatMapWithIndex = flatMapWithIndex;
exports.mapcatWithIndex = mapcatWithIndex;
exports.flatMap = flatMap;
exports.mapcat = mapcat;
exports.groupBy = groupBy;
exports.inGroupsOf = inGroupsOf;
exports.groupIntoPairs = groupIntoPairs;
exports.calcArrayIndexIfRemovedIndexes = calcArrayIndexIfRemovedIndexes;
exports.replaceAtIndex = replaceAtIndex;
exports.splitAtLast = splitAtLast;
exports.splitAtIndex = splitAtIndex;
exports.splitAtFirstExn = splitAtFirstExn;
exports.splitAtFirst = splitAtFirst;
exports.filterMapi = filterMapi;
exports.filterMap = filterMap;
exports.keepAsync = keepAsync;
exports.keepNot = keepNot;
exports.unique = unique;
exports.shortcutWithIndexAndState = shortcutWithIndexAndState;
exports.shortcutWithState = shortcutWithState;
exports.shortcutWithIndex = shortcutWithIndex;
exports.shortcut = shortcut;
exports.everyWithIndex = everyWithIndex;
exports.last = last;
exports.firstMatch = firstMatch;
exports.firstMatchi = firstMatchi;
exports.shortcutWithIndexAndStateAsync = shortcutWithIndexAndStateAsync;
exports.shortcutAsync = shortcutAsync;
exports.windowMap2 = windowMap2;
exports.window2ReduceWithIndex = window2ReduceWithIndex;
exports.smallest = smallest;
exports.takeWhile = takeWhile;
exports.collapsePairs = collapsePairs;
exports.packBy = packBy;
exports.fillTo = fillTo;
exports.splitAtEveryOccurrences = splitAtEveryOccurrences;
exports.findOneWithLowestScoreExn = findOneWithLowestScoreExn;
/* Util-SmartcutsMacros Not a pure module */
