// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Debug$SmartcutsMacros = require("../../rebug/Debug.bs.js");

var cdp = Debug$SmartcutsMacros.make(false, "cdp");

var chromeEventsWebNav = Debug$SmartcutsMacros.make(false, "chrome:events:webnav");

var chromeEventsTab = Debug$SmartcutsMacros.make(false, "chrome:events:tab");

var cmdClickPlayback = Debug$SmartcutsMacros.make(false, "cmd:click:playback");

var cmdTypeRecording = Debug$SmartcutsMacros.make(false, "cmd:type:recording");

var decco = Debug$SmartcutsMacros.make(false, "decco");

var dom = Debug$SmartcutsMacros.make(false, "DOM");

var fauna = Debug$SmartcutsMacros.make(false, "fauna");

var faunaSize = Debug$SmartcutsMacros.make(false, "fauna:size");

var imageKit = Debug$SmartcutsMacros.make(false, "imageKit");

var keyLogic = Debug$SmartcutsMacros.make(false, "keyLogic");

var playback = Debug$SmartcutsMacros.make(false, "playback");

var promiseRetries = Debug$SmartcutsMacros.make(false, "promise:retries");

var promiseCancels = Debug$SmartcutsMacros.make(false, "promise:cancels");

var recording = Debug$SmartcutsMacros.make(false, "recording");

var recordingContentEditing = Debug$SmartcutsMacros.make(false, "recording:contentEditing");

var recordingEventStreaming = Debug$SmartcutsMacros.make(false, "recording:eventStreaming");

var recordingSession = Debug$SmartcutsMacros.make(false, "recording:session");

var selector = Debug$SmartcutsMacros.make(false, "selector");

var stateMachineBg = Debug$SmartcutsMacros.make(false, "stateMachine:bg");

var stateMachineBgRecording = Debug$SmartcutsMacros.make(false, "stateMachine:bg:recording");

var stateMachineBgPlayback = Debug$SmartcutsMacros.make(false, "stateMachine:bg:playback");

var stateMachineBgPlaybackCmdControl = Debug$SmartcutsMacros.make(false, "stateMachine:bg:playback:cmdControl");

var stateMachineCs = Debug$SmartcutsMacros.make(false, "stateMachine:cs");

var stateMachineCsPlayback = Debug$SmartcutsMacros.make(false, "stateMachine:cs:playback");

var stateMachineCore = Debug$SmartcutsMacros.make(false, "stateMachine:core");

var sheets = Debug$SmartcutsMacros.make(false, "sheets");

var optimizedPlayback = Debug$SmartcutsMacros.make(false, "optimizedPlayback");

var utilSpy = Debug$SmartcutsMacros.make(false, "util:spy");

var utilWaitPromise = Debug$SmartcutsMacros.make(false, "util:waitPromise");

var ux = Debug$SmartcutsMacros.make(false, "ux");

var uxAuthoringCheckbox = Debug$SmartcutsMacros.make(false, "ux:authoring:checkbox");

exports.cdp = cdp;
exports.chromeEventsWebNav = chromeEventsWebNav;
exports.chromeEventsTab = chromeEventsTab;
exports.cmdClickPlayback = cmdClickPlayback;
exports.cmdTypeRecording = cmdTypeRecording;
exports.decco = decco;
exports.dom = dom;
exports.fauna = fauna;
exports.faunaSize = faunaSize;
exports.imageKit = imageKit;
exports.keyLogic = keyLogic;
exports.playback = playback;
exports.promiseRetries = promiseRetries;
exports.promiseCancels = promiseCancels;
exports.recording = recording;
exports.recordingContentEditing = recordingContentEditing;
exports.recordingEventStreaming = recordingEventStreaming;
exports.recordingSession = recordingSession;
exports.selector = selector;
exports.stateMachineBg = stateMachineBg;
exports.stateMachineBgRecording = stateMachineBgRecording;
exports.stateMachineBgPlayback = stateMachineBgPlayback;
exports.stateMachineBgPlaybackCmdControl = stateMachineBgPlaybackCmdControl;
exports.stateMachineCs = stateMachineCs;
exports.stateMachineCsPlayback = stateMachineCsPlayback;
exports.stateMachineCore = stateMachineCore;
exports.sheets = sheets;
exports.optimizedPlayback = optimizedPlayback;
exports.utilSpy = utilSpy;
exports.utilWaitPromise = utilWaitPromise;
exports.ux = ux;
exports.uxAuthoringCheckbox = uxAuthoringCheckbox;
/* cdp Not a pure module */
