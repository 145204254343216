// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var OptionMonad$SmartcutsMacros = require("./OptionMonad.bs.js");

function makeSome(x) {
  return Caml_option.some(x);
}

function bool_of_option(opt) {
  return opt !== undefined;
}

function set_default($$default, opt) {
  if (opt !== undefined) {
    return Caml_option.valFromOption(opt);
  } else {
    return $$default;
  }
}

function option_of_array(l) {
  if (l.length !== 0) {
    return Caml_option.some(Caml_array.get(l, 0));
  }
  
}

function option_of_list(l) {
  if (List.length(l) > 0) {
    return Caml_option.some(List.hd(l));
  }
  
}

function unbundleTuple(tuple) {
  if (tuple !== undefined) {
    return [
            Caml_option.some(tuple[0]),
            Caml_option.some(tuple[1])
          ];
  } else {
    return [
            undefined,
            undefined
          ];
  }
}

var UnExpectedNone = /* @__PURE__ */Caml_exceptions.create("OptionUtils-SmartcutsMacros.UnExpectedNone");

function extract_option_exn(opt) {
  if (opt !== undefined) {
    return Caml_option.valFromOption(opt);
  }
  throw {
        RE_EXN_ID: UnExpectedNone,
        Error: new Error()
      };
}

function set_array_default($$default, arr) {
  if (arr.length === 0) {
    return $$default;
  } else {
    return arr;
  }
}

function getExn(msg, x) {
  if (x !== undefined) {
    return Caml_option.valFromOption(x);
  }
  throw {
        RE_EXN_ID: "Invalid_argument",
        _1: "option is None; " + msg,
        Error: new Error()
      };
}

function $neg$bang$great(x, fn) {
  if (x !== undefined) {
    return Curry._1(fn, Caml_option.valFromOption(x));
  }
  
}

function withDefault($$default, x) {
  if (x !== undefined) {
    return Caml_option.valFromOption(x);
  } else {
    return $$default;
  }
}

function andThen(f, opt) {
  if (opt !== undefined) {
    return Curry._1(f, Caml_option.valFromOption(opt));
  }
  
}

function orElse(backupValue, value) {
  if (value !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(value));
  } else {
    return backupValue;
  }
}

function orElseWithFn(fn, value) {
  if (value !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(value));
  } else {
    return Curry._1(fn, undefined);
  }
}

function equalsValueIfUnwrapped(value, x) {
  if (x !== undefined) {
    return Caml_obj.caml_equal(value, Caml_option.valFromOption(x));
  } else {
    return false;
  }
}

function equalsSome(value, x) {
  if (x !== undefined) {
    return Caml_obj.caml_equal(value, Caml_option.valFromOption(x));
  } else {
    return false;
  }
}

function arrayOfOptionsToArrayOfUnboxedSomes(xs) {
  return Belt_Array.reduce(xs, [], (function (output, maybeX) {
                if (maybeX !== undefined) {
                  return $$Array.append(output, [Caml_option.valFromOption(maybeX)]);
                } else {
                  return output;
                }
              }));
}

function listOfOptionsToListOfUnboxedSomes(xs) {
  return List.rev(Belt_List.reduce(xs, /* [] */0, (function (output, maybeX) {
                    if (maybeX !== undefined) {
                      return {
                              hd: Caml_option.valFromOption(maybeX),
                              tl: output
                            };
                    } else {
                      return output;
                    }
                  })));
}

function toList(x) {
  if (x !== undefined) {
    return {
            hd: Caml_option.valFromOption(x),
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function toArray(x) {
  if (x !== undefined) {
    return [Caml_option.valFromOption(x)];
  } else {
    return [];
  }
}

function optionalString(s) {
  if (s.trim() === "") {
    return ;
  } else {
    return s;
  }
}

function stringify(stringify$1, maybeValue) {
  if (maybeValue !== undefined) {
    return "Some(" + (Curry._1(stringify$1, Caml_option.valFromOption(maybeValue)) + ")");
  } else {
    return "None";
  }
}

function keepAsync(predicatePromise, maybe) {
  if (maybe === undefined) {
    return Promise.resolve(undefined);
  }
  var x = Caml_option.valFromOption(maybe);
  return Curry._1(predicatePromise, x).then(function (result) {
              return Promise.resolve(result ? Caml_option.some(x) : undefined);
            });
}

var $$return = OptionMonad$SmartcutsMacros.$$return;

var wrap = OptionMonad$SmartcutsMacros.wrap;

var $great$great$eq = OptionMonad$SmartcutsMacros.$great$great$eq;

var $neg$question$great = OptionMonad$SmartcutsMacros.$neg$question$great;

var map = OptionMonad$SmartcutsMacros.$neg$question$great;

exports.$$return = $$return;
exports.wrap = wrap;
exports.$great$great$eq = $great$great$eq;
exports.$neg$question$great = $neg$question$great;
exports.makeSome = makeSome;
exports.bool_of_option = bool_of_option;
exports.set_default = set_default;
exports.option_of_array = option_of_array;
exports.option_of_list = option_of_list;
exports.unbundleTuple = unbundleTuple;
exports.UnExpectedNone = UnExpectedNone;
exports.extract_option_exn = extract_option_exn;
exports.set_array_default = set_array_default;
exports.getExn = getExn;
exports.map = map;
exports.$neg$bang$great = $neg$bang$great;
exports.withDefault = withDefault;
exports.andThen = andThen;
exports.orElse = orElse;
exports.orElseWithFn = orElseWithFn;
exports.equalsValueIfUnwrapped = equalsValueIfUnwrapped;
exports.equalsSome = equalsSome;
exports.arrayOfOptionsToArrayOfUnboxedSomes = arrayOfOptionsToArrayOfUnboxedSomes;
exports.listOfOptionsToListOfUnboxedSomes = listOfOptionsToListOfUnboxedSomes;
exports.toList = toList;
exports.toArray = toArray;
exports.optionalString = optionalString;
exports.stringify = stringify;
exports.keepAsync = keepAsync;
/* No side effect */
