// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Debug__Utils$SmartcutsMacros = require("./internal/Debug__Utils.bs.js");
var Debug__Browser$SmartcutsMacros = require("./internal/Debug__Browser.bs.js");

function Env_groupEnd(prim) {
  console.groupEnd();
  
}

var instances = {
  contents: /* [] */0
};

var names = {
  contents: /* [] */0
};

var skips = {
  contents: /* [] */0
};

function isForceEnabled(namespace) {
  return namespace[namespace.length] === "*";
}

function isSkipped(namespace) {
  return List.exists((function (re) {
                return re.test(namespace);
              }), skips.contents);
}

function isEnabled(namespace) {
  if (isForceEnabled(namespace)) {
    return true;
  } else if (isSkipped(namespace)) {
    return false;
  } else {
    return List.exists((function (re) {
                  return re.test(namespace);
                }), names.contents);
  }
}

function make(colorizeOpt, namespace) {
  var colorize = colorizeOpt !== undefined ? colorizeOpt : true;
  var timer = Debug__Utils$SmartcutsMacros.timer(undefined);
  var instance = {
    namespace: namespace,
    color: colorize ? /* Color */({
          _0: Debug__Utils$SmartcutsMacros.selectColor(Debug__Browser$SmartcutsMacros.colors, namespace)
        }) : /* NoColor */0,
    enabled: isEnabled(namespace)
  };
  instances.contents = {
    hd: instance,
    tl: instances.contents
  };
  var log = function (log$1, message, args) {
    var delta = Debug__Utils$SmartcutsMacros.Format.ms(Curry._1(timer, undefined));
    var color = instance.color;
    if (color) {
      var formatted = Debug__Browser$SmartcutsMacros.format(namespace, delta, message);
      var color$1 = "color:" + color._0;
      return Curry._1(log$1, {
                  hd: formatted,
                  tl: {
                    hd: color$1,
                    tl: {
                      hd: "color: inherit",
                      tl: {
                        hd: color$1,
                        tl: args
                      }
                    }
                  }
                });
    }
    var formatted$1 = "" + namespace + " " + message + " +" + delta;
    return Curry._1(log$1, {
                hd: formatted$1,
                tl: args
              });
  };
  var fnEnter = function (message, args) {
    var delta = Debug__Utils$SmartcutsMacros.Format.ms(Curry._1(timer, undefined));
    var color = instance.color;
    if (color) {
      var formatted = Debug__Browser$SmartcutsMacros.format(namespace, delta, message);
      var color$1 = "color:" + color._0;
      return Debug__Browser$SmartcutsMacros.group({
                  hd: formatted,
                  tl: {
                    hd: color$1,
                    tl: {
                      hd: "color: inherit",
                      tl: {
                        hd: color$1,
                        tl: args
                      }
                    }
                  }
                });
    }
    var formatted$1 = "" + namespace + " " + message + " +" + delta;
    return Debug__Browser$SmartcutsMacros.group({
                hd: formatted$1,
                tl: args
              });
  };
  var fnExit = function (ret) {
    log(Debug__Browser$SmartcutsMacros.log, "<-", {
          hd: ret,
          tl: /* [] */0
        });
    return Curry._1(Env_groupEnd, undefined);
  };
  var id = function (prim) {
    return prim;
  };
  return {
          log1: (function (m) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.log, m, /* [] */0);
              }
              
            }),
          log2: (function (m, a) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.log, m, {
                            hd: a,
                            tl: /* [] */0
                          });
              }
              
            }),
          log3: (function (m, a, b) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.log, m, {
                            hd: a,
                            tl: {
                              hd: b,
                              tl: /* [] */0
                            }
                          });
              }
              
            }),
          log4: (function (m, a, b, c) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.log, m, {
                            hd: a,
                            tl: {
                              hd: b,
                              tl: {
                                hd: c,
                                tl: /* [] */0
                              }
                            }
                          });
              }
              
            }),
          logMany: (function (m, args) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.log, m, args);
              }
              
            }),
          warn1: (function (m) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.warn, m, /* [] */0);
              }
              
            }),
          warn2: (function (m, a) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.warn, m, {
                            hd: a,
                            tl: /* [] */0
                          });
              }
              
            }),
          warn3: (function (m, a, b) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.warn, m, {
                            hd: a,
                            tl: {
                              hd: b,
                              tl: /* [] */0
                            }
                          });
              }
              
            }),
          warn4: (function (m, a, b, c) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.warn, m, {
                            hd: a,
                            tl: {
                              hd: b,
                              tl: {
                                hd: c,
                                tl: /* [] */0
                              }
                            }
                          });
              }
              
            }),
          warnMany: (function (m, args) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.warn, m, args);
              }
              
            }),
          error1: (function (m) {
              return log(Debug__Browser$SmartcutsMacros.error, m, /* [] */0);
            }),
          error2: (function (m, a) {
              return log(Debug__Browser$SmartcutsMacros.error, m, {
                          hd: a,
                          tl: /* [] */0
                        });
            }),
          error3: (function (m, a, b) {
              return log(Debug__Browser$SmartcutsMacros.error, m, {
                          hd: a,
                          tl: {
                            hd: b,
                            tl: /* [] */0
                          }
                        });
            }),
          error4: (function (m, a, b, c) {
              return log(Debug__Browser$SmartcutsMacros.error, m, {
                          hd: a,
                          tl: {
                            hd: b,
                            tl: {
                              hd: c,
                              tl: /* [] */0
                            }
                          }
                        });
            }),
          errorMany: (function (m, args) {
              return log(Debug__Browser$SmartcutsMacros.error, m, args);
            }),
          lazy1: (function (f) {
              if (instance.enabled) {
                return log(Debug__Browser$SmartcutsMacros.log, Curry._1(f, undefined), /* [] */0);
              }
              
            }),
          lazy2: (function (f) {
              if (!instance.enabled) {
                return ;
              }
              var match = Curry._1(f, undefined);
              return log(Debug__Browser$SmartcutsMacros.log, match[0], {
                          hd: match[1],
                          tl: /* [] */0
                        });
            }),
          lazy3: (function (f) {
              if (!instance.enabled) {
                return ;
              }
              var match = Curry._1(f, undefined);
              return log(Debug__Browser$SmartcutsMacros.log, match[0], {
                          hd: match[1],
                          tl: {
                            hd: match[2],
                            tl: /* [] */0
                          }
                        });
            }),
          lazy4: (function (f) {
              if (!instance.enabled) {
                return ;
              }
              var match = Curry._1(f, undefined);
              return log(Debug__Browser$SmartcutsMacros.log, match[0], {
                          hd: match[1],
                          tl: {
                            hd: match[2],
                            tl: {
                              hd: match[3],
                              tl: /* [] */0
                            }
                          }
                        });
            }),
          lazyMany: (function (f) {
              if (!instance.enabled) {
                return ;
              }
              var match = Curry._1(f, undefined);
              return log(Debug__Browser$SmartcutsMacros.log, match[0], match[1]);
            }),
          fn1: (function (m, pp1Opt, ppROpt, f, a) {
              var pp1 = pp1Opt !== undefined ? pp1Opt : id;
              var ppR = ppROpt !== undefined ? ppROpt : id;
              if (!instance.enabled) {
                return Curry._1(f, a);
              }
              fnEnter(m, {
                    hd: Curry._1(pp1, a),
                    tl: /* [] */0
                  });
              var ret = Curry._1(f, a);
              fnExit(Curry._1(ppR, ret));
              return ret;
            }),
          fn2: (function (m, pp1Opt, pp2Opt, ppROpt, f, a, b) {
              var pp1 = pp1Opt !== undefined ? pp1Opt : id;
              var pp2 = pp2Opt !== undefined ? pp2Opt : id;
              var ppR = ppROpt !== undefined ? ppROpt : id;
              if (!instance.enabled) {
                return Curry._2(f, a, b);
              }
              fnEnter(m, {
                    hd: Curry._1(pp1, a),
                    tl: {
                      hd: Curry._1(pp2, b),
                      tl: /* [] */0
                    }
                  });
              var ret = Curry._2(f, a, b);
              fnExit(Curry._1(ppR, ret));
              return ret;
            }),
          fn3: (function (m, pp1Opt, pp2Opt, pp3Opt, ppROpt, f, a, b, c) {
              var pp1 = pp1Opt !== undefined ? pp1Opt : id;
              var pp2 = pp2Opt !== undefined ? pp2Opt : id;
              var pp3 = pp3Opt !== undefined ? pp3Opt : id;
              var ppR = ppROpt !== undefined ? ppROpt : id;
              if (!instance.enabled) {
                return Curry._3(f, a, b, c);
              }
              fnEnter(m, {
                    hd: Curry._1(pp1, a),
                    tl: {
                      hd: Curry._1(pp2, b),
                      tl: {
                        hd: Curry._1(pp3, c),
                        tl: /* [] */0
                      }
                    }
                  });
              var ret = Curry._3(f, a, b, c);
              fnExit(Curry._1(ppR, ret));
              return ret;
            }),
          fn4: (function (m, pp1Opt, pp2Opt, pp3Opt, pp4Opt, ppROpt, f, a, b, c, d) {
              var pp1 = pp1Opt !== undefined ? pp1Opt : id;
              var pp2 = pp2Opt !== undefined ? pp2Opt : id;
              var pp3 = pp3Opt !== undefined ? pp3Opt : id;
              var pp4 = pp4Opt !== undefined ? pp4Opt : id;
              var ppR = ppROpt !== undefined ? ppROpt : id;
              if (!instance.enabled) {
                return Curry._4(f, a, b, c, d);
              }
              fnEnter(m, {
                    hd: Curry._1(pp1, a),
                    tl: {
                      hd: Curry._1(pp2, b),
                      tl: {
                        hd: Curry._1(pp3, c),
                        tl: {
                          hd: Curry._1(pp4, d),
                          tl: /* [] */0
                        }
                      }
                    }
                  });
              var ret = Curry._4(f, a, b, c, d);
              fnExit(Curry._1(ppR, ret));
              return ret;
            }),
          isEnabled: (function (param) {
              return instance.enabled;
            }),
          enable: (function (param) {
              instance.enabled = true;
              
            })
        };
}

function resetNamespaces(param) {
  names.contents = /* [] */0;
  skips.contents = /* [] */0;
  
}

function parseFilter(filter) {
  Belt_Array.keepMap(filter.split(/[\s,]+/), (function (x) {
              return x;
            })).filter(function (ns) {
          return ns !== "";
        }).forEach(function (namespace) {
        var namespace$1 = namespace.replace(/\*/g, ".*?");
        if (namespace$1[0] !== "-") {
          names.contents = {
            hd: new RegExp("^" + namespace$1 + "\$"),
            tl: names.contents
          };
          return ;
        }
        var namespace$2 = namespace$1.substr(1);
        skips.contents = {
          hd: new RegExp("^" + namespace$2 + "\$"),
          tl: skips.contents
        };
        
      });
  
}

function enable(filter) {
  Debug__Browser$SmartcutsMacros.save(filter);
  resetNamespaces(undefined);
  parseFilter(filter);
  return List.iter((function (i) {
                i.enabled = isEnabled(i.namespace);
                
              }), instances.contents);
}

function disable(param) {
  Debug__Browser$SmartcutsMacros.save(undefined);
  resetNamespaces(undefined);
  return List.iter((function (i) {
                i.enabled = false;
                
              }), instances.contents);
}

var filter = Debug__Browser$SmartcutsMacros.load(undefined);

if (filter !== undefined) {
  enable(filter);
}

exports.make = make;
exports.enable = enable;
exports.disable = disable;
exports.isEnabled = isEnabled;
/* filter Not a pure module */
